// @ts-nocheck
import { getUltraSDKModule } from '@utils/Ultra'
import { isServer } from '@utils/appAgent'
import { getFKPlatformInstance } from './init'
// import FKPlatform from 'fk-platform-sdk/web'

export const handleFlipkartNavigation = async () => {
    try {
        const FKPlatform = await getUltraSDKModule()
        if (!isServer() && FKPlatform && FKPlatform?.isPlatformAvailable()) {
            const fkPlatformObject = await getFKPlatformInstance()
            let navigationModule = fkPlatformObject.getModuleHelper().getNavigationModule()
            navigationModule.exitSession()
        }
    } catch (ex) {
        console.log(ex)
    }
}

export const clearPageHistoryForUltra = async () => {
    try {
        const FKPlatform = await getUltraSDKModule()
        if (!isServer() && FKPlatform && FKPlatform?.isPlatformAvailable()) {
            const fkPlatformObject = await getFKPlatformInstance()
            let navigationModule = fkPlatformObject.getModuleHelper().getNavigationModule()
            navigationModule.clearHistory()
        }
    } catch (ex) {
        console.log(ex)
    }
}

export const handleFlipkartError = async ({
    errorTitle,
    errorMessage,
    errorDescription,
    callbackMethod = () => {},
    ctaText,
    pageUrl
}) => {
    try {
        window.handleNavigation = callbackMethod
        const FKPlatform = await getUltraSDKModule()
        if (!isServer() && FKPlatform?.isPlatformAvailable()) {
            const fkPlatformObject = await getFKPlatformInstance()
            fkPlatformObject?.getModuleHelper()?.getErrorHandlerModule()?.openErrorHandlingFlow({
                errorTitle,
                errorMessage,
                errorDescription,
                callbackMethod: 'handleNavigation()',
                ctaText,
                pageUrl
            })
        }
    } catch (e) {
        console.log(e)
    }
}

export const paymentPageRedirection = async (paymentUrl: string, postPaymentRedirectionUrl: string) => {
    try {
        const FKPlatform = await getUltraSDKModule()
        if (!isServer() && FKPlatform && FKPlatform?.isPlatformAvailable()) {
            const fkPlatformObject = await getFKPlatformInstance()
            let navigationModule = fkPlatformObject.getModuleHelper().getNavigationModule()
            navigationModule.startPaymentByUrl(paymentUrl, postPaymentRedirectionUrl)
        }
    } catch (ex) {
        console.log(ex)
    }
}
