import { CLEARTRIP, FLIPKART } from '@constants/flights'

// export const couponBarColors = {
//     [FLIPKART]: {
//         text: '#108934',
//         background: '#E7F8EC',
//         iconFill: '#108934',
//         iconStroke: '#108934'
//     },
//     [CLEARTRIP]: {
//         text: '#ECF8F4',
//         background: '#11A670',
//         iconFill: 'none',
//         iconStroke: '#fff'
//     }
// }

export const fareCardBorderColor = {
    [FLIPKART]: 'bc-neutral-blue-900',
    [CLEARTRIP]: 'bc-neutral-900'
}

export const baggageIconColors = {
    [FLIPKART]: { iconFill: '#F0F5FF', iconStroke: '#2A55E5' },
    [CLEARTRIP]: { iconFill: '#FFEDE8', iconStroke: '#FF8D71' }
}

export const addonsCounterDisabledColor = {
    [FLIPKART]: '#75A1FF',
    [CLEARTRIP]: '#808080'
}

export const dateTabTextColor = {
    [FLIPKART]: {
        SELECTED_TAB_COLOR: '#2A55E5',
        BASE_COLOR: '#808080',
        CHEAPEST_PRICE_COLOR: '#108934'
    },
    [CLEARTRIP]: {
        SELECTED_TAB_COLOR: '#36c',
        BASE_COLOR: '#808080',
        CHEAPEST_PRICE_COLOR: '#11A670'
    }
}

export const couponCalloutColor = {
    [FLIPKART]: '#108934',
    [CLEARTRIP]: '#11A670'
}

export const modifySearchButtonType = {
    [FLIPKART]: 'fkprimary',
    [CLEARTRIP]: 'primary'
}

export const chipIconFill = {
    [FLIPKART]: '#2A55E5',
    [CLEARTRIP]: '#1C1B1F'
}

export const defaultBlue = {
    [FLIPKART]: '#2A55E5',
    [CLEARTRIP]: '#36c'
}

export const editIconColor = {
    [FLIPKART]: '#2A55E5',
    [CLEARTRIP]: '#36c'
}
