import { CLEARTRIP, FLIPKART } from '@constants/flights'
import { buildVariant } from '@root/configs/Whitelabel'
import { FLIGHTS_BASE_PATH } from '@constants/flights/paths'
import { appendQuery, checkDeviceType } from '@utils/appAgent'
import { handleFlipkartNavigation } from '@utils/Ultra/navigation'

const pollForIosCallback = (timeout = 4000, interval = 500) => {
    return new Promise((resolve, reject) => {
        const startTime = Date.now()

        const checkCallback = () => {
            if (window.iosData && window.iosData['app-agent']) {
                resolve('SUCCESS')
            } else if (Date.now() - startTime > timeout) {
                resolve('TIMEOUT')
            } else {
                setTimeout(checkCallback, interval)
            }
        }

        checkCallback()
    })
}

export const goToHome = async ({
    isReloadRequired = false,
    router = { replace: ({ pathname }: { pathname: String }) => {} },
    queryParams = {}
}) => {
    try {
        switch (buildVariant) {
            case CLEARTRIP:
                if (window.androidData && window.androidData['app-agent']) {
                    window?.MobileApp.onBackBtnClick('FLIGHTS_HOME')
                } else if (checkDeviceType() === 'iPhoneApp') {
                    if (window.iosData && window.iosData['app-agent']) {
                        window.webkit.messageHandlers['FLIGHTS_HOME'].postMessage('')
                    } else {
                        const response = await pollForIosCallback()
                        if (response === 'SUCCESS') {
                            window.webkit.messageHandlers['FLIGHTS_HOME'].postMessage('')
                        } else {
                            router.replace({
                                pathname: `${FLIGHTS_BASE_PATH}`
                            })
                        }
                    }
                } else if (window.iosData && window.iosData['app-agent']) {
                    window.webkit.messageHandlers['FLIGHTS_HOME'].postMessage('')
                } else if (isReloadRequired) {
                    window.location.href = FLIGHTS_BASE_PATH + appendQuery(queryParams)
                } else {
                    router.replace({
                        pathname: `${FLIGHTS_BASE_PATH}`
                    })
                }
                break
            case FLIPKART:
                handleFlipkartNavigation()
                break
            default:
                window.location.href = '/'
        }
    } catch (ex) {
        console.log(ex)
    }
}
