const createRippleHandler = (instance, eventName, action, cb) => {
  return function handleEvent(event) {
    if (cb) {
      cb.call(instance, event)
    }

    if (event.defaultPrevented) {
      return false
    }

    if (instance.ripple) {
      instance.ripple[action](event)
    }

    if (
      instance.props &&
      typeof instance.props[`on${eventName}`] === 'function'
    ) {
      instance.props[`on${eventName}`](event)
    }

    return true
  }
}

export default createRippleHandler
