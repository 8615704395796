import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const SectionHeader = ({ children, subheader, marginTop, onClick, className, enabelSticky, subheaderStyle = {} }) => {
    const stickyClasses = classnames(className, `p-sticky-t flex flex-between pt-${marginTop} pb-5 pl-16 pr-16`)
    const classes = classnames(className, `flex flex-between mt-${marginTop} mb-5 pl-16 pr-16`)
    return (
        <div className={enabelSticky ? stickyClasses : classes}>
            <p className="fs-15 fw-500 c-black-90">{children}</p>
            {subheader && (
                <span
                    className={classnames('fs-15 fw-500 c-black-90', className)}
                    onClick={onClick}
                    style={subheaderStyle}
                >
                    {subheader}
                </span>
            )}
        </div>
    )
}

SectionHeader.propTypes = {
    children: PropTypes.node,
    subheader: PropTypes.string,
    className: PropTypes.string,
    marginTop: PropTypes.number,
    onClick: PropTypes.func,
    enabelSticky: PropTypes.bool
}

SectionHeader.defaultProps = {
    children: null,
    subheader: '',
    className: '',
    marginTop: 30,
    onClick: () => {},
    enabelSticky: false
}

SectionHeader.displayName = 'SectionHeader'

export default SectionHeader
