import { shape, object, string, instanceOf } from 'prop-types'

export const pathType = shape({
  pathname: string,
  query: object,
  state: object
})

export const dateType = instanceOf(Date)

export const errorType = shape({
  code: string,
  message: string
})
