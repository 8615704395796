import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const SectionHelper = props => {
  const { children, additionalClassName = '' } = props
  const sectionHelperClass = classNames(
    'fs-13 mt-5 pl-16 pr-16 c-black-50 lh-title',
    [additionalClassName]
  )
  return <p className={sectionHelperClass}>{children}</p>
}

SectionHelper.propTypes = {
  children: PropTypes.node
}

SectionHelper.defaultProps = {
  children: null
}

SectionHelper.displayName = 'SectionHelper'

export default SectionHelper
