import React from 'react'
import CSSTransition from 'react-transition-group/CSSTransition'
import PropTypes from 'prop-types'

const Slide = ({ children, ...props }) => {

  
const {fromTop}  =  props;
 return(
  <CSSTransition {...props} classNames={fromTop ? "slideDown" :  "slide"}>
  {children}
</CSSTransition>
 )
}


Slide.propTypes = {
  children: PropTypes.node
}

Slide.defaultProps = {
  children: null
}

Slide.displayName = 'Animate(Slide)'

export default Slide
